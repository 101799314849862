import React, { useCallback } from 'react';
import { Color } from 'interfaces';
import {
  AggregatedProfileProperties,
  AggregatedSuperRequirementProperties,
  BookmarkedMode,
  CompletionListOrderBy,
  ComposedOrderProperties,
  Feature,
  Order,
  OrderDigitalSignature,
  OrderForm,
  OrderListMode,
  OrderPatientMetaPropertiesSchema,
  OrderRequirement,
  OrderRequirementSchema,
  OrderSample,
  OrderWizardLocalization,
  OrderWizardRequirement,
  RequirementAggregationType,
  RequirementType,
  UserType,
  WriteableOrderProperties,
  WriteableOrderPropertiesSchema,
} from 'interfaces/api';
import { arrayify } from 'utils/helpers';
import { BadgeType, Container, IconProps, ListItem } from 'components';
import { Translate, useIntl } from 'providers';
import messages from 'messages';
import { faClipboardMedical, faCommentAlt, faCommentAltCheck, faCommentAltExclamation, faFileCheck, faVial as faVialLight } from '@fortawesome/pro-light-svg-icons';
import { faPlusCircle, faUserCircle } from '@fortawesome/pro-solid-svg-icons';
import {
  faArchive,
  faBallPile,
  faBug,
  faCalendar,
  faFileAlt,
  faFileSignature as faFileSignatureRegular,
  faFont,
  faPaperPlane,
  faShoppingCart,
  faStethoscope,
  faSyringe,
  faTag,
  faTags as faTagsRegular,
  faTimesCircle,
  faTruckMedical,
  faVial,
} from '@fortawesome/pro-regular-svg-icons';
import { ListLayoutMode, useGuard } from 'containers';
import { OrdersListContext } from 'modules/orders/index';
import { filter, find, flatten, groupBy, isEmpty, isNull, keys, map, mapValues, omitBy, pick, sortBy, sum, sumBy, uniqBy, values } from 'lodash';
import { freeTextByType, getBadgeForRequirement, isProfile, isSuperRequirement } from 'modules/orders/containers/OrderWizard/utils';
import { useEnv } from 'store/components/injectEnv';
import { useAuthLid } from 'modules/auth/providers';
import { OrderAggregationConfig, OrderStatusColor } from 'modules/orders/interfaces';
import dayjs from 'dayjs';
import { DefaultOptionType } from 'antd/es/select';
import { FormatPrice } from 'providers/IntlProvider/FormatPrice';
import { useOfficeDoctorContext } from 'modules/orders/providers';

export const OrderAggregations: OrderAggregationConfig[] = [{
  type: RequirementAggregationType.Category,
  color: Color.Turquoise,
  icon: faTag,
}, {
  type: RequirementAggregationType.Indication,
  color: Color.SkyBlue,
  icon: faStethoscope,
  guard: { feature: Feature.IndicationSearch },
}, {
  type: RequirementAggregationType.OrderForm,
  color: Color.Yellow,
  icon: faFileAlt,
}, {
  type: RequirementAggregationType.Materials,
  color: Color.Salmon,
  icon: faVial,
}, {
  type: RequirementAggregationType.Analysis,
  color: Color.Bak,
  icon: faBug,
  className: 'bak-aggregation',
  guard: { feature: Feature.BakModule },
}, {
  type: RequirementAggregationType.Name,
  color: Color.Beige,
  icon: faFont,
}];

const orderModeLabels = messages.orders.modes;

export const OrderModes: ListLayoutMode<OrdersListContext>[] = [{
  type: undefined,
  title: messages.orders.mode.home,
  color: Color.Blue,
  icon: faTagsRegular,
}, {
  type: OrderListMode.Bookmarked,
  title: orderModeLabels[OrderListMode.Bookmarked],
  color: Color.Turquoise,
  icon: faCalendar,
  onSelect: ({ context }) => ({ filters: { bookmarkedMode: context.defaultBookmarkStatus || BookmarkedMode.Today } }),
}, {
  type: OrderListMode.Completion,
  title: orderModeLabels[OrderListMode.Completion],
  color: Color.SkyBlue,
  icon: faPaperPlane,
  guard: { feature: Feature.ClosureList },
  onSelect: ({ context }) => ({ filters: { completionOrder: context.defaultCompletionOrderBy || CompletionListOrderBy.OrderDate } }),
}, {
  type: OrderListMode.Transport,
  title: orderModeLabels[OrderListMode.Transport],
  color: Color.Yellow,
  icon: faTruckMedical,
  guard: { feature: Feature.TransportList },
  margin: 2,
  onSelect: () => ({
    transportationHistoryDate: dayjs(),
    transportationHistoryId: undefined,
  }),
}, {
  type: OrderListMode.Journal,
  title: orderModeLabels[OrderListMode.Journal],
  color: Color.Salmon,
  icon: faArchive,
  guard: { feature: Feature.Journal },
}, {
  type: OrderListMode.DigitalSignature,
  title: orderModeLabels[OrderListMode.DigitalSignature],
  color: Color.GreenSpring,
  icon: faFileSignatureRegular,
  guard: { feature: Feature.DigitalSignatureModule },
  margin: 3,
  onSelect: () => ({ filters: { digitalSignature: OrderDigitalSignature.Unsigned } }),
}, {
  type: OrderListMode.RealReRequest,
  title: orderModeLabels[OrderListMode.RealReRequest],
  color: Color.Beige,
  icon: faShoppingCart,
  guard: { feature: Feature.RealRerequest, forbidden: [UserType.ARZ, UserType.PAT] },
}, {
  type: OrderListMode.MassOrders,
  title: orderModeLabels[OrderListMode.MassOrders],
  color: Color.Bone,
  icon: faBallPile,
  guard: { feature: Feature.MassOrders, forbidden: [UserType.ARZ] },
}];

export const getListItemFlag = (entity: ComposedOrderProperties | Order) => {
  return flatten(arrayify(entity.status).map(s => OrderStatusColor[s]));
};

export const isComposedOrder = (entity: ComposedOrderProperties | Order): entity is ComposedOrderProperties => {
  return (entity as ComposedOrderProperties)?.orderCount !== undefined;
};

export const getOrderTransportationSummary = (orders: ListItem[]) => {
  const samples = mapValues(groupBy(flatten(orders.map(o => o.meta.materials as OrderSample[])), 'name'), s => sumBy(s, 'count'));
  const total = sum(values(samples));
  return { samples, total };
};

export const mapCostUnit = (costUnit: string) => ({
  P: 'Privat',
  PRIVAT: 'Privat',
  K: 'Kasse',
  KASSA: 'Kasse',
  KASSE: 'Kasse',
  I: 'Intern',
  INTERN: 'Intern',
})[costUnit?.toUpperCase()] || costUnit;

export const getPatientTitle = (title: string) => !isEmpty(title)
  ? title
  : (
    <span className={'empty-patient-name'}>
      <Translate message={messages.general.noPatientSelected}/>
    </span>
  );

export const getOrderDoctorDisplayName = (entity: ComposedOrderProperties | Order, showRoomNumber: boolean) => {
  let displayString = entity.selectedDoctor || (entity.doctor && entity.doctor.displayName);
  const composedOrder = isComposedOrder(entity) && !isEmpty(entity.hospitalRoomNumber);
  const noComposedOrder = !isComposedOrder(entity) && !isEmpty(entity.patient?.hospitalRoomNumber);
  const isKis = entity.doctor && entity?.doctor.localisation === OrderWizardLocalization.KIS || entity.doctor?.laboratoryLocalisation === OrderWizardLocalization.KIS;
  if (showRoomNumber && isKis) {
    if (noComposedOrder) {
      displayString = createStationAndRoomNumberDisplayString(entity.hospitalStation, entity.patient.hospitalRoomNumber);
    } else if (composedOrder) {
      displayString = createStationAndRoomNumberDisplayString(entity.hospitalStation, entity.hospitalRoomNumber);
    }
  } else if (showRoomNumber) {
    if (noComposedOrder) {
      displayString = createStationAndRoomNumberDisplayString(displayString, entity.patient.hospitalRoomNumber);
    } else if (composedOrder) {
      displayString = createStationAndRoomNumberDisplayString(displayString, entity.hospitalRoomNumber);
    }
  }
  return displayString;
};

const createStationAndRoomNumberDisplayString = (station: string, roomNumber: string): string => {
  return [station, roomNumber].filter(a => a && a.trim().length > 0).join(', ');
};

export const getCurrencyAsciiSign = (localization?: OrderWizardLocalization) => {
  if (localization === OrderWizardLocalization.CHE) {
    return <>CHF&nbsp;</>;
  }
  return <>&euro;</>;
};

const getRequirementBasePrice = (requirement: OrderRequirement, form?: OrderForm) => parseFloat(
  (find(requirement.formLevelPrices, p => p.formId === (form?.id || requirement.formId))?.price || requirement.price) + '',
);

export const getRequirementPrice = (requirement: OrderRequirement, form?: OrderForm, localization?: OrderWizardLocalization) => {

  if (form?.isPrivate && requirement.isCharged) {
    const price = getRequirementBasePrice(requirement, form);
    if (price > 0) {
      return <>{getCurrencyAsciiSign(localization)}{<FormatPrice price={price}/>}</>;
    } else {
      return <Translate message={messages.orders.wizard.priceOnApplication}/>;
    }
  }

  return null;
};

export const useGetTotalPriceLabel = () => {

  const { translate } = useIntl();
  const { wizardSettings } = useOfficeDoctorContext();

  return useCallback((requirements: OrderRequirement[], localization?: OrderWizardLocalization) => {

    const privateRequirements = requirements.filter(r => find(wizardSettings?.forms, { id: r.formId })?.isPrivate && r.isCharged);

    const formBaseCosts = sumBy(uniqBy(map(privateRequirements, r => r.form), 'id'), f => parseFloat((f?.basePrice || '0') + ''));
    const formRequirementCosts = sumBy(privateRequirements, requirement => getRequirementBasePrice(requirement) * (parseFloat((requirement.form?.priceFactor || '1') + '') || 1));
    const totalCosts = formRequirementCosts + formBaseCosts;

    const hasMicrobiological = requirements.filter(requirement => requirement.entityType === RequirementType.Microbiological).length > 0;
    const hasUndefinedCosts = privateRequirements.filter(requirement => !(getRequirementBasePrice(requirement) > 0)).length > 0;

    return privateRequirements.length > 0 && (
      <Container className={'total-price'}>
        <span className={'total-price-label'} dangerouslySetInnerHTML={{ __html: translate(messages.orders.wizard.costs) }}/>
        {hasMicrobiological || hasUndefinedCosts
          ? <Translate message={messages.orders.wizard.priceOnApplication}/>
          : <>{getCurrencyAsciiSign(localization)}<FormatPrice price={totalCosts}/></>
        }
      </Container>
    );
  }, [translate, wizardSettings]);

};

export const useOrderListRequirements = () => {

  const { BACKEND_URL } = useEnv();

  const { translate } = useIntl();
  const lid = useAuthLid();
  const guard = useGuard();

  return useCallback((order: Order): ListItem<OrderRequirement>[] => {

    return order.requirements?.reduce((items, requirement) => {

      const images = filter((requirement.materials || []).map((material) => {
        return `${BACKEND_URL}/api/orders/samples/${material.sampleId}/image/small?lid=${lid}`;
      }));

      const { id, longName, shortName, leftRight, origin, analyses, selectedAnalyses } = requirement;

      let title = longName;
      if (requirement.entityType === RequirementType.Microbiological) {
        title += ' (' + filter([
          shortName,
          !!leftRight && translate(messages.orders.requirementOptions.leftRight.options[leftRight]),
          origin,
        ]).join(', ') + ')';
      }

      const icons: IconProps[] = [];

      if (requirement.ruleInfoText) {
        icons.push(faFileCheck);
      }

      if (requirement.hint) {
        icons.push(faClipboardMedical);
      }

      if (requirement.reRequested) {
        icons.push({
          icon: [{ icon: faVialLight }, { icon: faPlusCircle, transform: 'shrink-7 down-4 right-3.5' }],
          tooltip: messages.orders.wizard.realReRequest.tooltip,
        });
      }

      if (requirement.cancelled_at) {
        icons.push({
          icon: faTimesCircle, color: Color.Red,
          tooltip: messages.orders.controls.cancelRequirement.isCancelled,
        });
      }

      guard({ feature: Feature.RequirementFreeText }, () => {
        if (requirement.freeTextAllowed) {
          icons.push(freeTextByType(requirement) ? faCommentAltCheck : (requirement.freeTextMandatory ? { icon: faCommentAltExclamation, color: Color.Red } : faCommentAlt));
        }
      });

      let subtitle = requirement.entityType === RequirementType.Clinical ? shortName : selectedAnalyses.map(a => find(analyses, { shortName: a })?.longName).join(', ');

      if (requirement.selectedLocalizations?.length > 0) {
        subtitle += ' - ' + requirement.selectedLocalizations.map(l => l.name1).join(', ');
      }

      return [...items, {
        id,
        icons,
        title,
        subtitle,
        images,
        faded: !!requirement.cancelled_at,
        meta: requirement,
        highlighted: false,
        badge: getBadgeForRequirement(requirement),
        badgeType: BadgeType.Inline,
        groupByValue: requirement.form.name,
      }];

    }, [] as ListItem[]);
  }, []);

};

export const getWriteableOrder = (order: WriteableOrderProperties, filterRequirements?: boolean) => omitBy({
  ...pick(omitBy(order, isNull), keys(WriteableOrderPropertiesSchema)),
  patient: pick(omitBy(order.patient, isNull), keys(OrderPatientMetaPropertiesSchema)),
  covid: pick(omitBy(order.covid, isNull), keys()),
  requirements: (order.requirements || [])
    .map(r => pick(omitBy(r, isNull), keys(OrderRequirementSchema)))
    .filter(r => !filterRequirements || r.profileId || r.form?.costUnit === order.costUnit),
}, isNull);

export const getDefaultCountries = (): DefaultOptionType[] => {
  const { COUNTRIES } = useEnv();

  return Object.keys(COUNTRIES).map((c): DefaultOptionType => {
    // @ts-expect-error todo
    return { value: c, label: COUNTRIES[c] };
  });
};

export const getPreferredCountries = (localisation: OrderWizardLocalization) => {
  const localisations = { [OrderWizardLocalization.AUT]: 'AT', [OrderWizardLocalization.CHE]: 'CH' };
  // @ts-expect-error todo
  const currentPreferredCountry = getDefaultCountries().find(l => l.value === localisations[localisation]);
  if (currentPreferredCountry) {
    return [currentPreferredCountry.value];
  }
  return null;
};

export const getRequirementId = (requirement: OrderWizardRequirement | AggregatedProfileProperties | AggregatedSuperRequirementProperties) => {
  return requirement.id + `:${JSON.stringify(requirement.flags)}:${JSON.stringify(requirement.filter)}:${JSON.stringify((requirement as any).selectedAnalyses)}`;
};

export const getRequirementShortName = (requirement: OrderWizardRequirement | AggregatedProfileProperties) => {
  return isProfile(requirement) || isSuperRequirement(requirement)
    ? requirement.shortName
    : requirement.shortName + (requirement.laboratoryGroup !== 'LA' ? ` (${requirement.laboratoryGroup})` : '');

};

export const SampleInLaboratoryIcon: IconProps = {
  icon: [{ icon: faSyringe }, { icon: faUserCircle, transform: 'shrink-7 down-4 right-3.5' }],
  tooltip: messages.orders.filters.sampleInLabFilter.label,
};

export const isSingleOrderView = (context: OrdersListContext) => {
  return context.mode !== OrderListMode.Bookmarked;
};

export const sortOrders = <T extends Order | WriteableOrderProperties>(orders: T[], sortByName: boolean) => sortBy(orders.map((order, idx) => {

  const { displayName, lastName, firstName } = order.patient || {};

  const sort = sortByName
    ? displayName || (lastName?.toLowerCase() + ' ' + firstName?.toLowerCase())
    : idx;

  return { order, idx, sort };

}), 'sort');
